import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { Animated } from "react-animated-css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import portrait from "../assets/images/nuhua-1.png";
import chef from "../assets/images/house-1.jpeg";
import white_chef from "../assets/images/house-2.jpeg";
import sustainable from "../assets/images/sustain.jpg";
import nuhua_3 from "../assets/images/nuhua-3.jpg";
import nuhua_4 from "../assets/images/nuhua-4.jpg";
import nuhua_5 from "../assets/images/nuhua-5.jpg";
import nuhua_6 from "../assets/images/nuhua-6.jpg";
import nuhua_7 from "../assets/images/nuhua-7.jpg";

const Home = () => {
  return (
    <>
      <div className={`bg-white p-4`}>
        <div className="flex flex-col-reverse md:flex md:flex-row justify-between py-10 my-10 max-w-screen-xl gap-10 mx-auto pt-5 md:pt-10 ">
          <div className="md:w-1/2 mb-2">
            <h1 className="text-4xl md:text-7xl font-pally font-extrabold">
              {/* <span className=" bg-app-bg bg-[length:100%]">
                <span className="">
                  
                  Best
                </span>
              </span> */}
              Discover Your Dream Property.
              <br />
            </h1>
            <p className="leading-relaxed text-md mt-5 text-zinc-700">
              At Nuhua, we turn dreams into addresses. With a wealth of
              experience in the dynamic Nigerian real estate market, we're
              dedicated to helping you find the perfect property that matches
              your unique desires and aspirations.
            </p>
            <div className="flex flex-row gap-2 md:gap-7 justify-start mt-16">
              <motion.a
                href="/contact"
                class="bg-blue-950 text-white font-bold p-3 rounded-lg inline-flex items-center w-56"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <span className=" ml-2 flex flex-row">
                  Contact sales{" "}
                  <svg
                    className="w-4 ml-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.5 12H20.33"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </motion.a>
              {/* <motion.a
                class="bg-white text-black font-bold border p-3 rounded-lg inline-flex items-center w-43 cursor-pointer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                href="/contact"
              >
                <span className=" ml-2 flex flex-row">
                  Contact sales{" "}
                  <svg
                    className="w-4 ml-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.5 12H20.33"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </motion.a> */}
            </div>
          </div>

          <motion.div
            className="md:w-1/2 flex justify-end"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={portrait}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={nuhua_4}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={nuhua_5}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={nuhua_3}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={nuhua_6}
                />
              </div>
            </Carousel>
            {/* <img className="rounded-lg fit" src={portrait} alt="landing.png" /> */}
          </motion.div>
        </div>
      </div>

      <div className="bg-zinc-100 p-5 md:p-20">
        <Animated
          animationIn="fadeInRight"
          animationOut="fadeOut"
          isVisible={true}
        >
          <div className="md:flex md:flex-row flex flex-col justify-between px-28 w-full mx-auto rounded-lg gap-5">
            <div className="md:w-1/4 mb-7 md:mb-0 rounded-md">
              <div className="flex flex-row gap-3">
                {/* <div className="">
                  <img src={fresh} alt="retail" className="w-10 md:w-12 pb-2" />
                </div> */}
                <div className="">
                  <h1 className="text-xl font-bold">Verified Listings</h1>
                  <p className="leading-snug tracking-tight text-zinc-700 mt-2">
                    Our properties are physically and legally verified, ensuring
                    your peace of mind.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-1/4 mb-7 md:mb-0 rounded-md">
              <div className="flex flex-row gap-3">
                {/* <div>
                  <img src={safe} alt="retail" className="w-10 md:w-12 pb-2" />
                </div> */}
                <div>
                  <h1 className="text-xl font-bold">End-to-End Expertise</h1>
                  <p className="leading-snug tracking-tight text-zinc-700 mt-2">
                    Our experienced team ensure you get expert services for
                    seamless property purchase.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-1/4 mb-7 md:mb-0 rounded-md">
              <div className="flex flex-row gap-2">
                {/* <div>
                  <img
                    src={qrcode}
                    alt="delivery"
                    className="w-10 md:w-12 pb-2"
                  />
                </div> */}
                <div>
                  <h1 className="text-xl font-bold">
                    Transparent & Trustworthy
                  </h1>
                  <p className="leading-snug tracking-tight text-zinc-700 mt-2">
                    Expect honesty, integrity, and clear communication in every
                    interaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-1/4 mb-7 md:mb-0 rounded-md">
              <div className="flex flex-row gap-3">
                {/* <div>
                  <img
                    src={farmer}
                    alt="delivery"
                    className="w-10 md:w-12 pb-2"
                  />
                </div> */}
                <div>
                  <h1 className="text-xl font-bold">
                    Exceptional Customer Experience
                  </h1>
                  <p className="leading-snug tracking-tight text-zinc-700 mt-2">
                    Expect nothing less than an exceptional customer experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </div>
      <div className="bg-white p-4">
        <div className="md:flex justify-start  px-15 py-8 max-w-screen-xl mx-auto gap-5">
          <div className="flex flex-col w-full">
            <div className="flex flex-col">
              <h3 className="font-bold text-5xl mb-5">Why Choose Us?</h3>
            </div>
            <div className="flex flex-row gap-10 w-full mt-5 overflow-x-auto">
              <div className="w-full md:w-1/3 bg-blue-950 text-white p-10 rounded-lg">
                <div className="mb-5">
                  <img className="rounded-lg w-96 h-44" src={chef} alt="Chef" />
                </div>
                <div className="mb-1 w-full">
                  <h3 className="font-bold text-2xl">Expertise</h3>
                </div>
                <div className="w-full">
                  <p className="text-zinc-300">
                    Backed by a team of seasoned real estate professionals, we
                    offer unparalleled expertise in navigating the Nigerian
                    property landscape
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/3 bg-blue-950 text-white p-10 rounded-lg">
                <div className="mb-5">
                  <img
                    className="rounded-lg w-96 h-44"
                    src={white_chef}
                    alt="Chef"
                  />
                </div>
                <div className="mb-1">
                  <h3 className="font-bold text-2xl">Tailored Solutions</h3>
                </div>
                <div>
                  <p className="text-zinc-300">
                    Understanding that each client is unique, we provide
                    personalized solutions that align with your specific
                    preferences and investment goals
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/3 bg-blue-950 text-white p-10 rounded-lg">
                <div className="mb-5">
                  <img
                    className="rounded-lg w-96 h-44"
                    src={sustainable}
                    alt="Chef"
                  />
                </div>
                <div className="mb-1">
                  <h3 className="font-bold text-2xl">
                    Transparency & Integrity
                  </h3>
                </div>
                <div>
                  <p className="text-zinc-300">
                    We uphold the highest standards of transparency and
                    integrity in every transaction, ensuring a seamless and
                    trustworthy experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-zinc-100 p-4">
        <div className="md:flex justify-start  px-15 py-8 max-w-screen-xl mx-auto gap-5">
          <div className="flex md:flex-row flex-col-reverse w-full">
            <div className="md:w-2/5 flex flex-col">
              <div className="bg-white p-10 my-3 rounded-lg">
                <div className="flex flex-row w-full justify-center items-center">
                  <div className="w-1/4">
                    <svg
                      className="w-12 h-12 text-black"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.4">
                        <path
                          d="M8.5 14.25C8.5 16.17 10.08 17.75 12 17.75C13.92 17.75 15.5 16.17 15.5 14.25"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.80945 2L5.18945 5.63"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.1895 2L18.8095 5.63"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <path
                        d="M2 7.8501C2 6.0001 2.99 5.8501 4.22 5.8501H19.78C21.01 5.8501 22 6.0001 22 7.8501C22 10.0001 21.01 9.8501 19.78 9.8501H4.22C2.99 9.8501 2 10.0001 2 7.8501Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                      />
                      <path
                        d="M3.5 10L4.91 18.64C5.23 20.58 6 22 8.86 22H14.89C18 22 18.46 20.64 18.82 18.76L20.5 10"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div className="w-3/4">
                    <h1 className=" font-bold text-2xl">Explore Properties</h1>
                    <h1 className="text-zinc-700 mt-1"></h1>
                  </div>
                </div>
              </div>
              <div className="bg-white p-10 my-3 rounded-lg">
                <div className="flex flex-row w-full justify-center items-center">
                  <div className="w-1/4">
                    <svg
                      className="w-12 h-12 text-black"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 10H22"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.5502 20.4998H6.44025C2.89025 20.4998 1.99023 19.6198 1.99023 16.1098V7.88977C1.99023 4.70977 2.73026 3.68977 5.52026 3.52977C5.80026 3.51977 6.11025 3.50977 6.44025 3.50977H17.5502C21.1002 3.50977 22.0002 4.38977 22.0002 7.89977V12.3098"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M6 16H10"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M22 18C22 18.75 21.79 19.46 21.42 20.06C20.73 21.22 19.46 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 15.79 15.79 14 18 14C20.21 14 22 15.79 22 18Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M16.4395 18L17.4294 18.99L19.5594 17.02"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="w-3/4">
                    <h1 className=" font-bold text-2xl">Schedule a Viewing</h1>
                    <h1 className=" text-zinc-700 mt-1"></h1>
                  </div>
                </div>
              </div>
              <div className="bg-white p-10 my-3 rounded-lg">
                <div className="flex flex-row w-full justify-center items-center">
                  <div className="w-1/4">
                    <svg
                      className="w-12 h-12 text-black"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5512 2H9.45117C7.65117 2 7.25118 2.90001 7.02118 4.01001L6.20117 7.92999H17.8012L16.9812 4.01001C16.7512 2.90001 16.3512 2 14.5512 2Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.2405 14.3199C19.3205 15.1699 18.6405 15.9 17.7705 15.9H16.4105C15.6305 15.9 15.5205 15.57 15.3805 15.15L15.2305 14.7199C15.0305 14.1299 14.9005 13.7299 13.8505 13.7299H10.1405C9.10054 13.7299 8.94054 14.1799 8.76054 14.7199L8.61054 15.15C8.47054 15.56 8.36054 15.9 7.58054 15.9H6.22054C5.35054 15.9 4.67054 15.1699 4.75054 14.3199L5.16055 9.89996C5.26055 8.80996 5.47054 7.91992 7.37054 7.91992H16.6205C18.5205 7.91992 18.7305 8.80996 18.8305 9.89996L19.2405 14.3199Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.2007 5.75H5.4707"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.5308 5.75H17.8008"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M7.65039 10.8301H9.8204"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M14.1797 10.8301H16.3497"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M12 17V18"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M12 21V22"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M3 18L2 22"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        opacity="0.4"
                        d="M21 18L22 22"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="w-3/4">
                    <h1 className=" font-bold text-2xl">Get Expert Advice</h1>
                    <h1 className=" text-zinc-700 mt-1"></h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-3/5 flex flex-col justify-center md:p-28 p-5">
              <div ClassName="flex ">
                <h1 className=" font-bold text-5xl tracking-wide leading-snug md:leading-tight">
                  Simple steps to get started
                </h1>
                <h3 className="mt-5 text-zinc-700">
                  Whether you are a first-time buyer, a seasoned investor, a
                  seller, or a commercial entity, our range of tailored
                  solutions addresses your unique needs. We pride ourselves on
                  customizing our services to ensure your real estate objectives
                  are met with precision.
                </h3>
              </div>
              <div className="mt-10">
                <motion.a
                  href="/contact"
                  class="bg-blue-950 text-white font-bold p-3 rounded-lg  inline-flex items-center w-56"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <span className="text-md ml-2 flex flex-row">
                    Contact sales for free{" "}
                    <svg
                      className="w-4 ml-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.5 12H20.33"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-white p-4">
        <div className="md:flex justify-between max-w-screen-xl mx-auto bg-nelsa_lime my-24 rounded-lg bg-world-map bg-contain bg-right bg-no-repeat">
          <div className="md:w-3/5">
            <div className="md:p-16 p-6 flex flex-col justify-between">
              <div className="flex flex-col">
                <h1 className="font-bold text-5xl tracking-tight text-black mb-5">
                  Stock and restock with ease
                </h1>
                <h3 className="text-zinc-800">
                  Handle everything from making orders and approving payments,
                  to checking balances, seeing your business performance and
                  lots more.
                </h3>
              </div>

              <div className="flex flex-col md:flex-row gap-2 md:gap-7 pt-10">
                <button class="bg-black text-white gap-2 font-medium mt-2 md:mt-0  p-3 rounded-lg inline-flex justify-center items-center md:w-42 w-44">
                  <img
                    className="md:h-6 md:w-6 h-4 w-4"
                    src={apple}
                    alt="apple"
                  />{" "}
                  <span className="md:text-sm text-xs font-bold">
                    Get on iPhone
                  </span>
                </button>
                <button class="bg-black text-white gap-2 font-medium mt-2 md:mt-0 p-3 rounded-lg inline-flex justify-center items-center md:w-42 w-44">
                  <img
                    className="md:h-6 md:w-6 h-4 w-4"
                    src={android}
                    alt="android"
                  />{" "}
                  <span className="md:text-sm text-xs font-bold">
                    Get on Android
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="md:w-2/5">
            <img className="self-end  w-full" src={iphone} alt="landing.png" />
          </div>
        </div>
      </div> */}
      <div className="bg-blue-950 p-4">
        <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
          <figure class="max-w-screen-md mx-auto">
            <svg
              class="h-12 mx-auto mb-3 text-zinc-400"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <blockquote>
              <p class="text-2xl font-medium text-white dark:text-white">
                "I couldn't have asked for a better team to guide me through my
                first property purchase. Nuhua Property & Homes made the entire
                process smooth and stress-free. Their attention to detail,
                market knowledge, and personalized approach ensured I found the
                perfect home. Highly recommended"
              </p>
            </blockquote>
            <figcaption class="flex items-center justify-center mt-6 space-x-3">
              <img
                class="w-6 h-6 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png"
                alt="profile picture"
              />
              <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                <div class="pr-3 font-medium text-gray-900 dark:text-white">
                  Ian Victor
                </div>
                <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                  GM at Alter Systems LTD
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </>
  );
};

export default Home;
