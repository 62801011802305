import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import hillcity_1 from "../assets/images/hillcity-1.png";
import hillcity_2 from "../assets/images/hillcity-2.jpeg";
import hillcity_3 from "../assets/images/hillcity-3.jpeg";
import hillcity_4 from "../assets/images/hillcity-4.jpeg";
import hillcity_5 from "../assets/images/hillcity-5.jpeg";
import hillcity_6 from "../assets/images/hillcity-6.jpeg";
import hillcity_7 from "../assets/images/hillcity-7.jpeg";
import hillcity_8 from "../assets/images/hillcity-8.jpeg";
import hillcity_9 from "../assets/images/hillcity-9.jpeg";
import hillcity_10 from "../assets/images/hillcity-10.jpeg";

const Properties = () => {
  return (
    <>
      <section class="bg-white dark:bg-gray-900">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div>
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_10}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_9}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_8}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_7}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_6}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_5}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_4}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_3}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_2}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={hillcity_1}
                />
              </div>
            </Carousel>
          </div>
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-blue-950 dark:text-white">
              Hill City Estate
            </h2>
            <p class="mb-6 font-light text-zinc-700 md:text-lg">
              Located at Kodape, Auta-balefi in Karu Local Govt Area, Nasarawa
              State.
            </p>
            <a
              href="/contact"
              class="inline-flex items-center text-white bg-blue-950 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              Contact sales
              <svg
                class="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Properties;
