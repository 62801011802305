import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const prod_year = new Date();
  return (
    <footer className="relative text-white p-4">
      <div className="px-15 py-10 max-w-screen-xl mx-auto">
        <div className="flex flex-col justify-between  md:flex md:flex-row w-full mb-10">
          <div className="flex-col w-1/2">
            <div className="">
              <Link to="/" className="text-3xl tracking-wide font-bold">
                {/* <img className="w-20" src={logo} alt="logo" /> */}
                Nuhua
              </Link>
            </div>
          </div>
          <div className="w-full mt-5 md:mt-0 md:w-2/3">
            <div className="flex flex-col md:flex md:flex-row w-full">
              <div className="flex flex-col w-full mb-5 mt-5 md:mt-0  md:w-1/4">
                <h3 className="font-bold pb-2.5 text-xl font-jakarta">
                  General
                </h3>
                <ul className="flex flex-col gap-y-2">
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="#">Our Impact</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/properties">Properties</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="#">FAQ</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/properties">Property updates</Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col w-full mb-5 mt-5 md:mt-0  md:w-1/4">
                <h3 className="font-bold pb-2.5 text-xl font-jakarta">
                  Work with us
                </h3>
                <ul className="flex flex-col gap-y-2">
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/about">Careers</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="#">Investor Relations</Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col w-full mb-5 mt-5 md:mt-0  md:w-2/4">
                <h3 className="font-bold pb-2.5 text-xl font-jakarta">
                  Contact
                </h3>
                <div>
                  <p>08036211116,</p>
                  <p>08126960482.</p>
                </div>
                <div className="mt-4 font-normal">
                  Address: Suite C209 & B105, Royal Plaza, Opp Building
                  Material. Mararaba, Nasarawa State.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-4">
          <div>
            <p className="text-sm">
              © Nuhua Property & Homes LTD, {prod_year.getFullYear()}
            </p>
          </div>
          <div>
            <p className="text-sm">
              Terms and conditions · Privacy policy · Cookies
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
