import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/images/nuhua.png";

const Header = () => {
  const [active, setActive] = useState(false);
  const onClose = () => {
    setActive(!active);
  };

  return (
    <header className="relative pt-2.5">
      <div className="max-w-7xl mx-auto flex items-center justify-between text-blue-950">
        <div className="flex flex-row">
          <Link
            to="/"
            className="flex flex-row text-3xl items-center justify-center font-alata tracking-wide font-bold"
          >
            <img className="w-20" src={logo} alt="logo" />
            {/* <div>
              <p>Nuhua</p>
            </div> */}
          </Link>
        </div>
        <div onClick={onClose} className="md:hidden cursor-pointer">
          <svg
            className="w-8 h-8"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
              fill="currentColor"
            />
            <path
              d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z"
              fill="currentColor"
            />
            <path
              d="M21 17.75H3C2.59 17.75 2.25 17.41 2.25 17C2.25 16.59 2.59 16.25 3 16.25H21C21.41 16.25 21.75 16.59 21.75 17C21.75 17.41 21.41 17.75 21 17.75Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <nav
          className={`
      ${!active && "hidden"}
       absolute flex flex-col top-full w-full left-0 z-20
       md:static md:w-auto md:flex-row md:flex md:space-x-5
      `}
        >
          <ul className="md:flex-row md:flex items-center md:space-x-2 ">
            <li className="link-none">
              <Link
                to="/properties"
                className="flex w-full px-2.5 text-md font-bold hover:text-zinc-700"
              >
                Properties
              </Link>
            </li>
            <li className="link-none">
              <Link
                to="/"
                className="flex w-full px-2.5 text-md font-bold hover:text-zinc-700"
              >
                Services
              </Link>
            </li>
            <li className="link-none ml-0">
              <Link
                to="/about"
                className="flex w-full px-2.5 text-md font-bold hover:text-zinc-700"
              >
                About
              </Link>
            </li>

            <li className="link-none">
              <Link
                to="/contact"
                className="flex w-full px-2.5 text-md font-bold hover:text-zinc-700"
              >
                Contact
              </Link>
            </li>
          </ul>

          {/* <a
            href="https://app.getnelsa.com"
            target="_blank"
            rel="noreferrer"
            className="flex justify-center items-center text-md bg-rose-600 hover:bg-nelsa_lime_2 font-bold text-white p-3 w-32 rounded-lg"
          >
            Sign In
          </a> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
