import React from "react";
import nuhua_3 from "../assets/images/nuhua-3.jpg";
import nuhua_4 from "../assets/images/nuhua-4.jpg";
import nuhua_5 from "../assets/images/nuhua-5.jpg";
import nuhua_6 from "../assets/images/nuhua-6.jpg";
import nuhua_7 from "../assets/images/nuhua-7.jpg";
import nuhua_10 from "../assets/images/nuhua-10.jpg";
import nuhua_11 from "../assets/images/nuhua-11.jpg";

const About = () => {
  return (
    <>
      <section class="bg-white max-w-7xl mx-auto">
        <div class="flex flex-row items-center gap-20">
          <div className="w-full flex flex-col items-center">
            {/* <a
          href="#"
          class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-white bg-zinc-900 rounded-full"
          role="alert"
        >
          <span class="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">
            New
          </span>{" "}
          <span class="text-sm font-medium">
            Flowbite is out! See what's new
          </span>
          <svg
            class="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a> */}
            <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-blue-950 md:text-4xl">
              About Nuhua Property & Homes Limited
            </h1>
            <p class=" text-zinc-700">
              Welcome to Nuhua Property & Homes Limited, where your real estate
              aspirations find their perfect home. Established with a vision to
              redefine the real estate landscape in Nigeria, we are a team of
              dedicated professionals passionate about transforming your
              property dreams into reality.
            </p>
          </div>
        </div>
      </section>
      <section class="bg-white">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="text-zinc-700 ">
            <h2 class="mb-4 text-3xl tracking-tight font-extrabold text-blue-950 ">
              Our Story
            </h2>
            <p class="mb-4">
              Founded in 2021 by real estate visionary, Gimba Titus, Nuhua
              Property & Homes Limited began as a small venture fueled by a
              relentless commitment to integrity, transparency, and client
              satisfaction. Over the years, our unwavering dedication to these
              core values has been the cornerstone of our growth and success.
            </p>
            <p>
              At Nuhua Property & Homes Limited, our mission is simple yet
              profound: to make house ownership as easy as possible with peace
              of mind. We do this by upholding our core values of:
            </p>
            <div className="mt-5">
              <ul>
                <li>
                  <span class="font-bold">Excellence: </span> Striving for
                  excellence in every interaction, service, and transaction.
                </li>
                <li>
                  <span class="font-bold">Integrity: </span> Conducting business
                  with unwavering honesty, transparency, and ethical practices.
                </li>
                <li>
                  <span class="font-bold">Innovation: </span> Embracing
                  innovation and staying at the forefront of the industry's
                  advancements.
                </li>
                <li>
                  <span class="font-bold">Client-Centric Approach: </span>{" "}
                  Placing our clients' needs and aspirations at the heart of
                  everything we do.
                </li>
                <li>
                  <span class="font-bold">Community Engagement: </span>{" "}
                  Contributing positively to the communities we serve.
                </li>
              </ul>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg"
              src={nuhua_10}
              alt="office content 1"
            />
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src={nuhua_11}
              alt="office content 2"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
