import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Riders from "./pages/Riders";
import Sustainability from "./pages/Sustainability";
import Vendors from "./pages/Vendors";
import About from "./pages/About";
import Properties from "./pages/Properties";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/properties" element={<Properties />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
